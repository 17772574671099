.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative !important;
    margin: 0px 0px 40px 0px;
}

.company-collection-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 30px 15px 10px 15px;
    margin: 0 0 45px;
    font-size: 30px;
    color: white;
}

.company-collection-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 30px 15px 10px 15px;
    margin: 0 0 45px;
    font-size: 18px;
    color: white;
}

.author-product-search:after {
    /* padding-left: 25px;
    background: url("https://static.thenounproject.com/png/101791-200.png") no-repeat left;
    background-size: 20px; */
    font-family: 'FontAwesome';
    content: '\f274';
    position: absolute;
    left: 6px;
}