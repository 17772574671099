.nft-image-preview {
    border-radius: 12px;
    background-color: #000;
    margin-bottom: 98px;
    display: block;
    margin: auto;
    max-width: 284px;
}

.nft-remove-btn {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: var(--nft-gradient-color);
    color: #fff;

    right: 8px;
    display: block;
    position: absolute;
    top: 8px;
    outline: none;
    border: none;
}
.nft-remove-btn:hover{
    opacity: 0.8;
}