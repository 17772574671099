.nft-image-modal {
  max-height: 284px;
  display: block;
  margin: auto;
  border-radius: 12px;
  margin-bottom: 18px;
}

.profile-image-modal {
  max-height: 142px;
  display: block;
  margin: auto;
  /* border-radius: 12px; */
  margin-bottom: 18px;
}