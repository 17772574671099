.nft-uploader {
    max-width: 480px;
    margin: auto;
    background: var(--nft-gradient-color);
    padding: 48px;
    border-radius: 12px;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    
}

.nft-uploader input {
    cursor: pointer;
}

.nft-uploader:hover {
    box-shadow: 0px 0px 4px 5px rgb(45 45 45);
    -webkit-box-shadow: 0px 0px 4px 5px rgb(45 45 45);
    -moz-box-shadow:  0px 0px 4px 5px rgb(45 45 45);
    transition: box-shadow 0.3s ease-in-out;

}




.nft-uploader svg {
    width: 84px;
    color: azure;
    
}